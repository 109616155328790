/**
 * Bootstrap uses HTML5 Boilerplate which has CSS to set a max-width
 * on images when in print mode. The consequence is that Google Maps
 * doesn't display properly in print mode.
 *
 * See: https://github.com/h5bp/html5-boilerplate/issues/1741
 */
@media print {
  img {
    max-width: none !important;
  }
}
